@media (max-width: 575px) {
  /* Set full width for comment buttons on mobile breakpoint */
  .custom-comment-form-button {
    width: 100%;
  }
}

.comment-markdown,
.comment-markdown p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 16px;
}
.comment-markdown > *:last-child {
  margin-bottom: 0 !important;
}
.w-md-editor-toolbar {
  border-color: #a6a6a6;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  padding: 7px;
}
.w-md-editor {
  box-shadow: none;
  border: 1px solid #a6a6a6;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}
.w-md-editor-preview {
  box-shadow: none;
  border-left: 1px solid #a6a6a6;
}
.w-md-editor-text-input {
  border: 0 !important;
  background: none !important;
  -webkit-text-fill-color: #333;
  overflow: auto;
}
.comment-markdown blockquote {
  padding: 0 1em;
  color: #8b949e;
  border-left: 0.25em solid #8b949e;
}
.wmde-markdown-color {
  display: none;
}
.wmde-markdown {
  display: block;
}
.w-md-editor-text {
  min-height: 100% !important;
}
.w-md-editor-toolbar svg {
  width: 16px;
  height: 16px;
}
.w-md-editor-toolbar button,
.w-md-editor-toolbar ul {
  display: flex;
  align-items: center;
}
